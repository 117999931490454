import { Injectable, inject } from '@angular/core';

import { Customer } from '../models/customer';
import { QuoteDetail } from '../models/quote/quote-detail';
import { Note } from '../models/quote/note';

import { User } from '../models/user';

import { Location } from '../models/location';

import { Employee } from '../models/employee/employee';

import { EmployeeCert } from '../models/employee/employee-cert';

import { Equipment } from '../models/equipment/equipment';

import { AppConfig } from './app.config';
import { Workorder } from '../models/workorder/workorder';
import { ShipmentData } from '../models/storage/shipment-data';

/**
 * Urls used within the application.
 * Stringified for convenience, since most of the Angular's HTTP tools work with strings.
 */
@Injectable({ providedIn: 'root' })
export class AppUrlsConfig {
	private readonly appConfigService = inject(AppConfig);

	/** Auth-related routes. */
	public readonly auth = {
		login: this.toApi('login'),
		logout: this.toApi('logout'),
	} as const;

	/** Routes for getting/editing current user's info. */
	public readonly user = {
		currentProfile: this.toApi('profile'),
		list: this.toApi('users'),
		getAll: this.toApi('users', 'get-all'),
		changePassword: this.toApi('change-password'),
		forgotPassword: this.toApi('forgot-password'),
		detail: (id: User['id']) => this.toApi('users', id.toString()),
		picture: (id: User['id']) => this.toApi('users', id.toString(), 'picture'),
		profilePicture: this.toApi('profile-picture'),
	} as const;

	/** Routes related to quotes. */
	public readonly quote = {
		list: this.toApi('quotes'),
		revisions: (id: QuoteDetail['id']) => this.toApi('revisions', id.toString(), 'revisions'),
		createNote: (id: QuoteDetail['id']) => this.toApi('quotes', id.toString(), 'notes'),
		editNote: (id: QuoteDetail['id'], noteId: Note['id']) =>
			this.toApi('quotes', id.toString(), 'notes', noteId.toString()),
		jobOptions: this.toApi('jobs/form-data'),
		stages: this.toApi('quotes', 'stages'),
		detail: (id: QuoteDetail['id']) => this.toApi('quotes', id.toString()),
		storeDescription: (id: QuoteDetail['id']) => this.toApi('quotes', id.toString(), 'description'),
		nonBillableEquipment: (id: QuoteDetail['id']) => this.toApi('quotes', id.toString(), 'non-billable-equipments'),
		equipment: (id: QuoteDetail['id']) => this.toApi('quotes', id.toString(), 'equipments'),
		editLabors: (id: QuoteDetail['id']) => this.toApi('quotes', id.toString(), 'labors'),
		editOwnedEquipment: (id: QuoteDetail['id']) => this.toApi('quotes', id.toString(), 'owned-equipments'),
		editStorageHandling: (id: QuoteDetail['id']) => this.toApi('quotes', id.toString(), 'storage-handling'),
		editTransportation: (id: QuoteDetail['id']) => this.toApi('quotes', id.toString(), 'transportations'),
		editRentalEquipment: (id: QuoteDetail['id']) => this.toApi('quotes', id.toString(), 'rental-equipments'),
		editOtherSection: (id: QuoteDetail['id']) => this.toApi('quotes', id.toString(), 'others'),
		summary: (id: QuoteDetail['id']) => this.toApi('quotes', id.toString(), 'summary'),
		uploadInternalFile: (id: QuoteDetail['id']) => this.toApi('quotes', id.toString(), 'internal-files'),
		downloadOrDeleteInternalFile: (id: QuoteDetail['id'], fileId: number) =>
			this.toApi('quotes', id.toString(), 'internal-files', fileId.toString()),
		availableStages: (id: QuoteDetail['id']) => this.toApi('quotes', 'stages', id.toString()),
		editStage: (id: QuoteDetail['id']) => this.toApi('quotes', id.toString(), 'stages'),
		mutateClarification: (id: QuoteDetail['id']) => this.toApi('quotes', id.toString(), 'clarifications'),
		reasons: this.toApi('quotes', 'stages', 'lost-reasons'),
		listStatistic: this.toApi('quotes', 'statistics'),
		paginatedList: this.toApi('quotes', 'pagination'),
		downloadPreview: (id: QuoteDetail['id']) => this.toApi('quotes', id.toString(), 'download-pdf'),
		editLaborAndEquipmentBundles: (id: QuoteDetail['id']) => this.toApi('quotes', id.toString(), 'labor-equipment-bundles'),
		statuses: this.toApi('quotes', 'statuses'),
		markup: (id: QuoteDetail['id']) => this.toApi('quotes', id.toString(), 'markup'),
		contractIds: (id: QuoteDetail['id']) => this.toApi('quotes', id.toString(), 'contract-ids'),
		jobNames: this.toApi('quotes', 'job-names'),
	} as const;

	/** Routes related to revisions. */
	public readonly revisions = {
		detail: (id: QuoteDetail['id']) => this.toApi('revisions', id.toString()),
		stages: this.toApi('revisions', 'stages'),
		availableStages: (id: QuoteDetail['id']) => this.toApi('revisions', 'stages', id.toString()),
		createNote: (id: QuoteDetail['id']) => this.toApi('revisions', id.toString(), 'notes'),
		editStage: (id: QuoteDetail['id']) => this.toApi('revisions', id.toString(), 'stages'),
		editNote: (id: QuoteDetail['id'], noteId: Note['id']) =>
			this.toApi('revisions', id.toString(), 'notes', noteId.toString()),
		storeDescription: (id: QuoteDetail['id']) => this.toApi('revisions', id.toString(), 'description'),
		nonBillableEquipment: (id: QuoteDetail['id']) => this.toApi('revisions', id.toString(), 'non-billable-equipments'),
		equipment: (id: QuoteDetail['id']) => this.toApi('revisions', id.toString(), 'equipments'),
		editLabors: (id: QuoteDetail['id']) => this.toApi('revisions', id.toString(), 'labors'),
		editOwnedEquipment: (id: QuoteDetail['id']) => this.toApi('revisions', id.toString(), 'owned-equipments'),
		editStorageHandling: (id: QuoteDetail['id']) => this.toApi('revisions', id.toString(), 'storage-handling'),
		editTransportation: (id: QuoteDetail['id']) => this.toApi('revisions', id.toString(), 'transportations'),
		editRentalEquipment: (id: QuoteDetail['id']) => this.toApi('revisions', id.toString(), 'rental-equipments'),
		editOtherSection: (id: QuoteDetail['id']) => this.toApi('revisions', id.toString(), 'others'),
		uploadInternalFile: (id: QuoteDetail['id']) => this.toApi('revisions', id.toString(), 'internal-files'),
		downloadOrDeleteInternalFile: (id: QuoteDetail['id'], fileId: number) =>
			this.toApi('revisions', id.toString(), 'internal-files', fileId.toString()),
		mutateClarification: (id: QuoteDetail['id']) => this.toApi('revisions', id.toString(), 'clarifications'),
		restoreQuote: (id: QuoteDetail['id']) => this.toApi('revisions', id.toString(), 'restore'),
		restoreRevision: (quoteId: QuoteDetail['id'], revisionId: QuoteDetail['id']) =>
			this.toApi('revisions', quoteId.toString(), 'restore', revisionId.toString()),
		list: (id: QuoteDetail['id']) => this.toApi('revisions', id.toString(), 'revisions'),
		downloadPreview: (id: QuoteDetail['id']) => this.toApi('revisions', id.toString(), 'download-pdf'),
		summary: (id: QuoteDetail['id']) => this.toApi('revisions', id.toString(), 'summary'),
		editLaborAndEquipmentBundles: (id: QuoteDetail['id']) => this.toApi('revisions', id.toString(), 'labor-equipment-bundles'),
		markup: (id: QuoteDetail['id']) => this.toApi('revisions', id.toString(), 'markup'),
	} as const;

	/** Routes related to customers. */
	public readonly customer = {
		list: this.toApi('customers'),
		details: (id: Customer['id']) => this.toApi('customers', id.toString()),
		contacts: (id: Customer['id']) => this.toApi('customers', id.toString(), 'contacts'),
		getJsonData: this.toApi('customers', 'json_data')
	} as const;

	/** Routes related to equipment. */
	public readonly equipment = {
		nonBillableList: this.toApi('non-billable-equipments'),
		detail: (id: Equipment['id']) => this.toApi('equipments', id.toString()),
		types: this.toApi('equipments/types'),
		transferTypes: this.toApi('equipments/transfer-types'),
		disposalTypes: this.toApi('equipments/disposals/types'),
		defaultTypes: this.toApi('equipments/default-types'),
		uploadFile: this.toApi('equipments/upload-file'),
		machines: this.toApi('equipments-machines'),
		list: this.toApi('equipments'),
		locations: this.toApi('equipments/locations'),
		logsByEquipment: (id: Equipment['id']) => this.toApi('equipments', id.toString(), 'details'),
		logs: this.toApi('equipments/l-details/equipment-log'),
		logDetail: (logId: number) => this.toApi('equipments/details', logId.toString()),
		createLog: this.toApi('equipments/details'),
		transfer: (id: Equipment['id']) => this.toApi('equipments', id.toString(), 'transfer'),
		dispose: this.toApi('equipments', 'disposals'),
	} as const;

	/** Routes related to location. */
	public readonly location = {
		jobSites: (id: number) => this.toApi('locations', id.toString(), 'jobsites'),
		list: this.toApi('locations'),
		sameLocations: (id: Location['id']) => this.toApi('locations', id.toString(), 'locations-same-region'),
	} as const;

	/** Routes related to rate card. */
	public readonly rateCard = {
		list: this.toApi('rate-cards'),
		rateCards: this.toApi('rate-cards'),
	} as const;

	/** Routes related to rate card. */
	public readonly components = {
		save: (type: string) => this.toApi('components', type.toString()),
		list: (id: string) => this.toApi('components', id.toString()),
		labors: (id: number) => this.toApi('components', id.toString(), 'labors'),
		owned: (id: number) => this.toApi('components', id.toString(), 'owned'),
		storage: (id: number) => this.toApi('components', id.toString(), 'storage'),
		transportations: (id: number) => this.toApi('components', id.toString(), 'transportations'),
		others: (id: number) => this.toApi('components', id.toString(), 'others'),
		rentals: (id: number) => this.toApi('components', id.toString(), 'rentals'),
		dyn: (gi: string) => this.toApi('components', 'dyn', gi.toString()),
		expense: this.toApi('components', 'expense'),
		rTypes: this.toApi('components', 'rateTypes'),
	} as const;

	/** Routes related to clarification. */
	public readonly clarification = {
		list: this.toApi('clarifications'),
		divisions: this.toApi('clarifications', 'divisions'),
		endMarkets: this.toApi('clarifications', 'end-markets'),
		groups: this.toApi('clarifications', 'groups'),
		regionals: this.toApi('clarifications', 'regionals'),
	} as const;

	/** Routes related to permission. */
	public readonly permission = {
		list: this.toApi('permissions'),
		listByRole: (roleId: number) => this.toApi('permissions', 'roles', roleId.toString()),
		roles: this.toApi('permissions', 'roles'),
		userPermissions: (userId: number) => this.toApi('permissions', 'users', userId.toString(), 'permissions'),
		canCreateQuotes: (userId: number) => this.toApi('permissions', 'users', userId.toString(), 'can-create-quotes'),
		canViewQuote: (userId: number, quoteId: number) =>
			this.toApi('permissions', 'users', userId.toString(), 'can-view-quote', quoteId.toString()),
		canEditQuote: (userId: number, quoteId: number) =>
			this.toApi('permissions', 'users', userId.toString(), 'can-edit-quote', quoteId.toString()),
		canEditUsers: (userId: number) => this.toApi('permissions', 'users', userId.toString(), 'can-add-users'),
		canDeleteEquipment: (userId: number) => this.toApi('permissions', 'users', userId.toString(), 'can-delete-equipment'),
		canDeleteRentalEquipment: (userId: number) => this.toApi('permissions', 'users', userId.toString(), 'can-delete-rental-equipment'),
		regions: (userId: number) => this.toApi('permissions', 'companies', userId.toString(), 'regions'),
		canEditPermissions: (userId: number) =>
			this.toApi('permissions', 'users', userId.toString(), 'can-edit-permissions'),
		edit: (userId: number) => this.toApi('users', userId.toString(), 'permissions'),
		canApproveQuote: (userId: number) => this.toApi('permissions', 'users', userId.toString(), 'can-approve-quote'),
		canViewDJB: (userId: number) => this.toApi('permissions', 'users', userId.toString(), 'can-view-djb'),
		isPermittedTo: (userId: number) => this.toApi('permissions', 'users', userId.toString(), 'is-permitted-to'),
		canAccessEquipmentManager: (userId: number) =>
			this.toApi('permissions', 'users', userId.toString(), 'can-access-equipment-manager'),
		canViewStorage: (userId: number) => this.toApi('permissions', 'users', userId.toString(), 'can-view-storage'),
		userPermissionsForEmployeePerLocation: (userId: number, locationId: Location['id']) =>
			this.toApi('permissions', 'users', userId.toString(), 'location', locationId.toString(), 'employee-permissions'),
	} as const;

	/** Routes related to term and conditions. */
	public readonly terms = {
		list: this.toApi('term-and-conditions'),
	} as const;

	/** Routes related to logos. */
	public readonly logos = {
		list: this.toApi('logos'),
	} as const;

	/** Routes related to change orders. */
	public readonly changeOrders = {
		detail: (id: QuoteDetail['id'] | QuoteDetail['quoteNumber']) => this.toApi('change-orders', id.toString()),
		list: (quoteNumber: QuoteDetail['id'] | QuoteDetail['quoteNumber']) =>
			this.toApi('change-orders', quoteNumber.toString(), 'change-orders'),
		summary: (id: QuoteDetail['id']) => this.toApi('change-orders', id.toString(), 'summary'),
		stages: this.toApi('change-orders', 'stages'),
		availableStages: (id: QuoteDetail['id']) => this.toApi('change-orders', 'stages', id.toString()),
		editStage: (id: QuoteDetail['id']) => this.toApi('change-orders', id.toString(), 'stages'),
		equipment: (id: QuoteDetail['id']) => this.toApi('change-orders', id.toString(), 'equipments'),
		editLabors: (id: QuoteDetail['id']) => this.toApi('change-orders', id.toString(), 'labors'),
		editOwnedEquipment: (id: QuoteDetail['id']) => this.toApi('change-orders', id.toString(), 'owned-equipments'),
		editStorageHandling: (id: QuoteDetail['id']) => this.toApi('change-orders', id.toString(), 'storage-handling'),
		editTransportation: (id: QuoteDetail['id']) => this.toApi('change-orders', id.toString(), 'transportations'),
		editRentalEquipment: (id: QuoteDetail['id']) => this.toApi('change-orders', id.toString(), 'rental-equipments'),
		editOtherSection: (id: QuoteDetail['id']) => this.toApi('change-orders', id.toString(), 'others'),
		downloadPreview: (id: QuoteDetail['id']) => this.toApi('change-orders', id.toString(), 'download-pdf'),
		editLaborAndEquipmentBundles: (id: QuoteDetail['id']) => this.toApi('change-orders', id.toString(), 'labor-equipment-bundles'),
		markup: (id: QuoteDetail['id']) => this.toApi('change-orders', id.toString(), 'markup'),
	} as const;

	/** Routes related to masquerade. */
	public readonly masquerade = {
		enter: (id: number) => this.toApi('masquerade', id.toString()),
		leave: this.toApi('masquerade'),
	} as const;

	/** Routes related to notifications. */
	public readonly notifications = {
		list: this.toApi('notifications'),
		markAsRead: this.toApi('notifications', 'mark-as-read'),
		markAllAsRead: this.toApi('notifications', 'mark-all-as-read'),
	} as const;

	/** Routes related to resources. */
	public readonly resource = {
		getPreferredVendors: this.toApi('resources', 'preferred-vendors'),
		getAccountingFiles: this.toApi('resources', 'accounting'),
		getInsuranceFiles: this.toApi('resources', 'insurance_cards'),
		getMarketingFiles: this.toApi('resources', 'marketing'),
		getSalesFiles: this.toApi('resources', 'sales_resources'),
		getReportingFiles: this.toApi('resources', 'reporting_resources'),
		getDispatchFiles: this.toApi('resources', 'dispatch_resources'),
		getWarehouseFiles: this.toApi('resources', 'warehouse_resources'),
		downloadResource: this.toApi('resources', 'download-resource'),
		createFolder: this.toApi('resources', 'create_folder'),
		checkForDupes: this.toApi('resources', 'check_for_dupes'),
		getMsaInfo: this.toApi('resources', 'msa_dashboard')
	}

	/** Routes related to navigation. */
	public readonly navigation = {
		list: this.toApi('navigations'),
		detail: (id: number) => this.toApi('navigations', id.toString()),
	} as const;

	/** Routes related to Workorders */
	public readonly workorder = {
		get: (location: string) => this.toApi('jobs', location.toString()),
		find: (id: number) => this.toApi('jobs','detail',id.toString()),
		reps: (location: string) => this.toApi('jobs','reps', location.toString()),
		save: this.toApi('jobs'),
		crud: (id: Workorder['id']) => this.toApi('jobs', id.toString()),
		convertible: (location: string) => this.toApi('jobs','convertible',location.toString()),
		generate: this.toApi('jobs', 'generate'),
		print: (id: Workorder['id']) => this.toApi('jobs', 'print_workorder', id.toString()),
		day: this.toApi('jobs', 'day'),
		deleteDay: (id: number) => this.toApi('jobs', 'day', id.toString()),
		copyDay: this.toApi('jobs', 'day', 'copy'),
		getDays: (id: number) => this.toApi('jobs', id.toString(), 'days'),
	} as const;
	
	/** Routes related to employees. */
	public readonly employees = {
		list: this.toApi('employees'),
		groups: this.toApi('employees', 'groups'),
		titles: this.toApi('employees', 'titles'),
		types: this.toApi('employees', 'types'),
		certCategories: this.toApi('employees', 'certs', 'categories'),
		detail: (id: Employee['id']) => this.toApi('employees', id.toString()),
		certs: (id: Employee['id']) => this.toApi('employees', id.toString(), 'certs'),
		certDetail: (certId: EmployeeCert['id']) => this.toApi('employees', 'certs', certId.toString()),
		files: (id: Employee['id']) => this.toApi('employees', id.toString(), 'files'),
		deleteFile: (fileId: number) => this.toApi('employees', 'files', fileId.toString()),
	} as const;

	/** Routes related to lookup data. */
	public readonly lookup = {
		glItems: this.toApi('lookups/gl-items'),
		glItemDesc: this.toApi('lookups/gl-items-desc'),
	} as const;
	
	/** Routes related to storage. */
	public readonly storage = {
		getRecipient: this.toApi('storage', 'recipient'),
		sendReceipt: this.toApi('storage', 'send_receipt'),
		getNotices: this.toApi('storage', 'get_notices'),
		getShipments: this.toApi('storage', 'get_shipments'),
		getLocations: this.toApi('storage', 'get_locations'),
		getStorageLocations: this.toApi('storage', 'get_storage_locations'),
		getStorageRates: this.toApi('storage', 'get_rates_by_location'),
		getShipmentData: this.toApi('storage', 'get_shipment_data'),
		getShipmentItems: this.toApi('storage', 'get_shipment_items'),
		getBolByCheckoutId: this.toApi('storage', 'get_bol_by_checkout_id'),
		// TODO - probably a better way to send GET parameters
		getDropoffLocsByLocation: (location: string) => this.toApi('storage', 'dropoff_locs?location='+location),
		getSalesRepsByLocation: (location: string) => this.toApi('storage', 'sales_reps?location='+location),
		updateWarehouse: this.toApi('storage', 'update_warehouse'),
		updateDriver: this.toApi('storage', 'update_driver'),
		getHandlingRows: this.toApi('storage', 'get_handling_rows'),
		getNewHandlingRow: this.toApi('storage', 'get_new_handling_row'),
		removeHandlingRow: this.toApi('storage', 'remove_handling_row'),
		saveHandlingRow: this.toApi('storage', 'save_handling_row'),
		getStartTimes: this.toApi('storage', 'get_start_times'),
		getAcData: this.toApi('storage', 'get_ac_data'),
		getHistory: this.toApi('storage', 'get_history'),
		saveNewShipment: this.toApi('storage', 'new_shipment'),
		getAllImagesForShipmentRecord: this.toApi('storage', 'get_all_images_for_shipment_record'),
		downloadRecord: this.toApi('storage', 'download_record'),
		printPicklist: this.toApi('storage', 'print_picklist'),
		findItemByTerm: this.toApi('storage', 'find_item_by_term'),
		processCheckout: this.toApi('storage', 'process_checkout'),
		completeCheckout: this.toApi('storage', 'complete_checkout'),
		addAndProcessCheckout: this.toApi('storage', 'add_and_process_checkout'),
		getCheckoutPartials: this.toApi('storage', 'get_checkout_partials'),
		setItemForCheckout: this.toApi('storage', 'set_item_for_checkout'),
		remainderGroupNumItems: this.toApi('storage', 'remainder_group_num_items'),
		updateBol: this.toApi('storage', 'update_bol'),
		markShipped: this.toApi('storage', 'mark_shipped'),
		checkoutIsDone: this.toApi('storage', 'checkout_is_done'),
		createRate: this.toApi('storage', 'create_rate'),
		updateRate: this.toApi('storage', 'update_rate'),
		hasItemsForRate: this.toApi('storage', 'has_items_for_rate'),
		getAllItemsForRate: this.toApi('storage', 'get_all_items_for_rate'),
		removeRate: this.toApi('storage', 'remove_rate'),
		updateItemsAndRemoveRate: this.toApi('storage', 'update_items_and_remove_rate'),
		delShipment: this.toApi('storage', 'del_shipment'),
		exportFromFilters: this.toApi('storage', 'export_from_filters'),
		getCorporateCustomers: this.toApi('storage', 'get_corporate_customers'),
		getCorporateReport: this.toApi('storage', 'get_corp_report'),
		getStorageReport: this.toApi('storage', 'get_csv_report'),
		getResultsByTerm: this.toApi('storage', 'get_results_by_term'),

		/** Items */
		undoItem: this.toApi('storage/items', 'undo_item'),
		copyItem: this.toApi('storage/items', 'copy_item'),
		updateItem: this.toApi('storage/items', 'update_item'),
		removeItem: this.toApi('storage/items', 'remove_item'),
		newItemRow: this.toApi('storage/items', 'new_item_row'),
		printSingleBarcode: this.toApi('storage/items', 'print_single_barcode'),
		printAllBarcodes: this.toApi('storage/items', 'print_all_barcodes'),
		batchUpdate: this.toApi('storage/items', 'batch_update'),
		getGroupNumbers: this.toApi('storage/items', 'get_group_numbers'),
		itemFiles: (itemId: number) => this.toApi('storage/items', itemId.toString(), 'item_files'),

		/** Files */
		files: (id: number, type: string) => this.toApi('storage', id.toString(), type, 'files'),
		downloadOrDeleteFile: (fileId: number) => this.toApi('storage', 'files', fileId.toString()),
		thumbnail: (fileId: number) => this.toApi('storage', 'files', fileId.toString(), 'thumbnail'),
		saveFileGroup: this.toApi('storage/files', 'save_file_group'),
	}

	/** Routes related to jobs */
	public readonly job = {
		getJsonData: this.toApi('jobs', 'json_data'),
		getEmployeeCategories: this.toApi('jobs/scheduler', 'employee_categories'),
		getJobsByDate: this.toApi('jobs/scheduler', 'jobs_by_date'),
		getEmployeesByDate: this.toApi('jobs/scheduler', 'employees_by_date'),
		getRequiredEquipment: this.toApi('jobs/scheduler', 'required_equipment'),
		getDjbDay: this.toApi('jobs/scheduler', 'djb_day'),
		getDjbWeek: this.toApi('jobs/scheduler', 'djb_week')
	}

	public readonly d365 = {
		getNames: this.toApi('d365','names'),
		prospects: this.toApi('d365', 'prospects'),
		projects: this.toApi('d365', 'projects'),
		projectContracts: this.toApi('d365', 'projectContracts'),
		projectFunding: this.toApi('d365', 'projectFunding'),
		projectFundingRule: this.toApi('d365', 'projectFundingRule'),
		expense: this.toApi('d365', 'expense'),
		hours: this.toApi('d365', 'hours'),
		revenue: this.toApi('d365', 'revenue'),
		adpHours: this.toApi('d365', 'adpHours'),		
	}

	public readonly schedule = {
		detail: (id: number) => this.toApi('schedules', id.toString()),
		jobColors: this.toApi('schedules','colors'),
		jobStartTimes: this.toApi('schedules','times'),
		searchWO: this.toApi('schedules','searchWO'),
		searchQuotes: this.toApi('schedules','searchQuotes'),
		save: this.toApi('schedules', 'job'),
		saveQuote: this.toApi('schedules', 'quote'),
		get: (location: string, job_date: string) => this.toApi('schedules','all', location, job_date),
		eventDates: (location: string, start: string, end: string) => this.toApi('schedules', 'eventDates', location, start, end),
		getAllEq: (location: string, job_date: string) => this.toApi('schedules','all-equipments', location, job_date),
		getAllEm: (location: string, job_date: string) => this.toApi('schedules','all-employees', location, job_date),
		getEquipments: (job_id: number, job_date: string) => this.toApi('schedules','equipments', job_id.toString(), job_date),
		getEmployees: (job_id: number, job_date: string) => this.toApi('schedules', 'employees', job_id.toString(), job_date),
		getEmployee: (job_id: number, employee_id: number) => this.toApi('schedules', 'employee', 'get', job_id.toString(), employee_id.toString()),
		getEquipment: (job_id: number, equipment_id: number) => this.toApi('schedules', 'equipment', 'get', job_id.toString(), equipment_id.toString()),
		getScheduledEmployees: (job_id: number) => this.toApi('schedules', 'employee','all', job_id.toString()),
		getScheduledEquipment: (job_id: number) => this.toApi('schedules', 'equipment','all', job_id.toString()),
		addEmployee: this.toApi('schedules', 'employee', 'add'),
		addEquipment: this.toApi('schedules', 'equipment', 'add'),
		updateEmployee: this.toApi('schedules', 'employee'),
		updateEquipment: this.toApi('schedules', 'equipment'),
		removeEmployee: (job_id: number, employee_id: number) => this.toApi('schedules','employee', job_id.toString(), employee_id.toString()),
		removeEquipment: (job_id: number, equipment_id: number) => this.toApi('schedules','equipment', job_id.toString(), equipment_id.toString()),
		getPendingHours: (location: string) => this.toApi('schedules', 'd365', location),
		getStorageData: (schedule_id: number) => this.toApi('schedules', 'storage', schedule_id.toString()),
		copyJob: this.toApi('schedules','copy'),
		files: (id: number) => this.toApi('schedules', 'files', id.toString()),
		deleteFile: (fileId: number) => this.toApi('schedules', 'files', fileId.toString()),
		rateCardLabors: (job_id: number) => this.toApi('schedules', job_id.toString(), 'labors'),
		rateCardEquip: (job_id: number) => this.toApi('schedules', job_id.toString(), 'equipments'),
		emplTitles: this.toApi('schedules', 'titles'),
		markJobD365Ready: this.toApi('schedules', 'd365'),
		WODay: (job_id: number) => this.toApi('schedules', job_id.toString(), 'day'),
		saveWODay: this.toApi('schedules', 'day'),

	}

	/**
	 * Checks whether the url is application-scoped.
	 * @param url Url to check.
	 */
	public isApplicationUrl(url: string): boolean {
		return url.startsWith(this.appConfigService.apiUrl);
	}

	/**
	 * Checks whether the specified url is calling an auth-related endpoint.
	 * @param url Url to check.
	 */
	public isAuthUrl(url: string): boolean {
		return Object.values(this.auth).find(authUrl => authUrl.includes(url)) != null;
	}

	private toApi(...args: readonly string[]): string {
		const path = args.join('/');
		return new URL(path, this.appConfigService.apiUrl).toString();
	}
}
